(function ($) {
  Drupal.behaviors.fullWidth2rowV1 = {
    attach: function (context, settings) {
      $('.full-width-2-row-v1').each(function () {
        var thisFormatter = $(this);
        var hasDiagnostic = thisFormatter.children().attr('data-quiz-overlay');
        var link = thisFormatter.find('.button').attr('href');

        if (typeof link !== 'undefined') {
          if (typeof hasDiagnostic !== 'undefined') {
            thisFormatter.wrapInner('<a href="' + link + '" class="wrapper-link ' + hasDiagnostic + '"></a>');
            if ($.isFunction(Drupal.behaviors.launchFoundationFinder.attach)) {
              Drupal.behaviors.launchFoundationFinder.attach(thisFormatter);
            }
            if ($.isFunction(Drupal.behaviors.launchBlueOcean.attach)) {
              Drupal.behaviors.launchBlueOcean.attach(thisFormatter);
            }
          } else {
            thisFormatter.wrapInner('<a href="' + link + '" class="wrapper-link"></a>');
          }
        }

        // Custom CTA added via the node's WYSIWYG editor using these classes: button cta offer-cta. This custom CTA will need the data-productid and the data-skubaseid and should be in this format:
        // <a class="button cta offer-cta" href="#" title="Add to Bag" data-product-title="Product Title" data-productid="PROD12345" data-skubaseid="12345" style="display: inline-block;">Add to Bag</a>
        var cta = thisFormatter.find('.button.cta.offer-cta');

        if (typeof cta !== 'undefined' && cta.hasClass('offer-cta')) {
          thisFormatter.find('.wrapper-link').css({ 'cursor': 'default' });
          cta.on('click', function(e) {
            e.preventDefault();
            // iterate through each product div to get sku for 'add all to cart'
            var buySkus = [];
            var self = $(this);
            var productId = cta.attr('data-productid');
            var skuBaseId = cta.attr('data-skubaseid');

            self.each(function () {
              buySkus.push(skuBaseId);
            });

            var loadingId = site.loading.show({
              'target': cta,
              'matchTarget': ['display', 'height', 'width'],
              'css': {
                'text-align': 'center',
                'vertical-align': 'bottom',
                'margin-bottom': '0'
              }
            });

            // add sku array to cart
            site.addToCart({
              skuBaseId: buySkus
            });

            $(document).one('addToCart.success addToCart.failure', function () {
              site.loading.hide(loadingId);
            });
          });
        }
      });
    }
  };
})(jQuery);
